$(function() {
  var scrollPosition = document.body.scrollTop + document.documentElement.scrollTop;
  var $devlogSubnav = $('#devlog-subnav');
  var $subnavBtn = $devlogSubnav.find('#devlog-subnav-btn-menu');

  function addScrollStick($elm) {
    $(window).on('load resize scroll', function() {
      scrollPosition = document.body.scrollTop + document.documentElement.scrollTop;
      if (scrollPosition > 90) {
        $elm.addClass('stick-scroll');
      }
      else {
        $elm.removeClass('stick-scroll');
      }
    });
  }

  function toggleMenu() {
    $devlogSubnav.toggleClass('closed');
    $subnavBtn.toggleClass('closed');
  }

  addScrollStick($devlogSubnav);

  $subnavBtn.on( 'click', function() {
    toggleMenu();
  });

  window.slider.init();
})

$(function () {
  var $playlist = $('.js-video-wrapper');
  var $videoCards = $('.js-video-card');
  var playlistId = $playlist.attr('data-id');

  
  $.ajax({
    dataType: 'json',
    url: window.apiServicesUrl + 'v1/youtube/playlist-items/' + playlistId,
    success: function (res) {
      $playlist.removeClass('hide');
      if (res.status === 1) {
        var videos = res.data.items;

        if (videos && videos.length > 0) {
          $.each(videos, function(index, item) {
            if (index >=30 || !$videoCards[index]) {
              return false;
            }
            var $videoItem = $($videoCards[index]);

            $videoItem.attr('data-src',
              'https://www.youtube.com/embed/' + item.videoId);
            $videoItem.attr('title', item.title);

            $videoItem.find('.youtube-video-image').
              css('background-image',
                'url(https://img.youtube.com/vi/' + item.videoId +
                '/hqdefault.jpg)');
            $videoItem.find('.youtube-video-description').text(item.title);
            $videoItem.removeClass('hide');
          });
        }
      }
    },
    error: function (xhr, type, status) {
      console.log(status, type, xhr);
    }
  });

  iframePlayer.init();

  window.popup.init({
    onOpen: function(e) {
      var videoCard = $(e.target).closest('.js-video-card-popup');

      iframePlayer.setSrc(videoCard.data('src'));
    },
    onClose: function() {
      iframePlayer.destroy();
    },
  });
});

$(function() {
    var lazyLoadInstance = new LazyLoad({
      // Your custom settings go here
    });

    mediumZoom(document.querySelectorAll('.zoomable'));
})


